import React from "react";
import { Link } from "gatsby";
import KeyFiGreyIcon from "../../images/icons/keyfi-grey.svg";
import LogoWhite from "../../images/logoWhite.svg";
import RobotWithKeyIcon from "../../images/icons/robot-with-key.svg";
import { useTranslation } from "react-i18next";
import "./footerbottom.css";

const footerLinks = [
  {
    href: "https://www.instagram.com/keyfi.app/",
    text: "Instagram",
  },
  {
    href: "https://www.linkedin.com/company/keyfi-app/",
    text: "LinkedIn",
  },
  {
    href: "https://www.reddit.com/r/KeyFi_Official/",
    text: "Reddit",
  },
  {
    href: "https://keyfi-app.medium.com/",
    text: "Medium",
  },
  {
    href: "https://www.youtube.com/c/KeyFi",
    text: "YouTube",
  },
];

export default function FooterBottomWrap({ isPro }) {
  const { t } = useTranslation();

  const FooterLinks = ({ link }) => {
    return link.map((footerLink, indx) => {
      return (
        <a href={footerLink.href} target="_blank" rel="noreferrer" key={indx}>
          {footerLink.text}
        </a>
      );
    });
  };

  return (
    <div className="footerBottomWrap">
      <div className="icon">
        {isPro ? (
          <img src={LogoWhite} alt="KeyFi icon" />
        ) : (
          <img src={KeyFiGreyIcon} alt="KeyFi icon" />
        )}
      </div>
      <div className="akarmi">
        <div className="columnsWrap">
          <div>
            <div className="column">
              <h4>{t("homepage.footer.company")}</h4>
              <Link to="/about">{t("homepage.footer.about")}</Link>
              <Link to="/team">{t("homepage.footer.team")}</Link>
              <Link to="/press">{t("homepage.footer.press")}</Link>
              <Link to="https://angel.co/company/keyfi-app">
                {t("homepage.footer.angelList")}
              </Link>
              <Link to="/contact">{t("homepage.footer.contact")}</Link>
            </div>
          </div>
          <div>
            <div className="column">
              <h4>{t("homepage.footer.resources")}</h4>
              <Link to="/whitepaper">{t("homepage.footer.whitepaper")}</Link>
              <Link to="/credentials">{t("homepage.footer.credential")}</Link>
              <Link to="/roadmap">{t("homepage.footer.roadmap")}</Link>
              <a
                href="https://docs.keyfi.com/"
                target="_blank"
                rel="noreferrer"
              >
                {t("homepage.footer.userGuide")}
              </a>
            </div>
          </div>
          <div>
            <div className="column">
              <h4>{t("homepage.footer.community")}</h4>
              <FooterLinks link={footerLinks} />
            </div>
          </div>
        </div>
        <div className="robotIcon">
          <img src={RobotWithKeyIcon} alt="Robot with Key" />
        </div>
      </div>
    </div>
  );
}
