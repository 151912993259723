import React from "react";
import styled from "styled-components";
import { breakPoints } from "./../theme";

import GithubIcon from "../../images/icons/github.svg";
import TwitterIcon from "../../images/icons/twitter.svg";
import TelegramIcon from "../../images/icons/telegram.svg";
import DiscordIcon from "../../images/icons/discord.svg";

import GithubIconWhite from "../../images/pro/Github_White.svg";
import TwitterIconWhite from "../../images/pro/Twitter_White.svg";
import TelegramIconWhite from "../../images/pro/Telegram_White.svg";
import DiscordIconWhite from "../../images/pro/Discord_White.svg";

const SocialWidgetWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1.5em 0 ${(props) => (props.isPro ? "0" : "6em")};
  width: 100%;

  @media (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
  }

  @media (max-width: 1240px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const SocialWidget = styled.div`
  background: ${(props) =>
    props.isPro
      ? `linear-gradient(116.14deg, #363D45 -22.83%, rgba(74, 91, 114, 0) 123.39%)`
      : `white`};
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  margin: 0 2em 2em;
  padding: 1.5em;
  width: 340px;

  &:hover {
    box-shadow: 0 6px 32px 0 rgba(0, 0, 0, 0.15);
    background: linear-gradient(
      42deg,
      rgba(12, 167, 235, 1) 6%,
      rgba(0, 90, 211, 1) 100%
    );
    color: ${(props) => props.theme.white1};
  }

  img {
    margin-right: 1em;
  }

  h4 {
    font-size: 1.5em;
    margin: 0 0 0.25em;
  }

  h5 {
    font-family: "Telex", sans-serif;
    font-size: 1em;
    margin: 0;

    @media (max-width: 1300px) {
      font-size: 0.7em;
    }
  }

  @media (max-width: ${breakPoints.sm}px) {
    margin: 0 auto 1em;
    width: 310px;
  }

  @media (max-width: 1300px) {
    margin: 0 1em 1em;
    width: 270px;
  }

  @media (max-width: 1140px) {
    margin: 0 1em 1.5em;
  }

  @media (max-width: 600px) {
    margin: 0 auto 1.5em;
  }
`;

export default function SocialWidgets({ isPro }) {
  return (
    <SocialWidgetWrap isPro={isPro}>
      <a href="http://github.com/KEYFIAI/" target="_blank" rel="noreferrer">
        <SocialWidget isPro={isPro}>
          <div>
            {isPro ? (
              <img src={GithubIconWhite} alt="GitHub" />
            ) : (
              <img src={GithubIcon} alt="GitHub" />
            )}
          </div>
          <div>
            <h4>Github</h4>
            <h5>http://github.com/KEYFIAI/</h5>
          </div>
        </SocialWidget>
      </a>

      <a href="https://twitter.com/keyfi_app" target="_blank" rel="noreferrer">
        <SocialWidget isPro={isPro}>
          <div>
            {isPro ? (
              <img src={TwitterIconWhite} alt="Twitter" />
            ) : (
              <img src={TwitterIcon} alt="Twitter" />
            )}
          </div>
          <div>
            <h4>Twitter</h4>
            <h5>@keyfi_app</h5>
          </div>
        </SocialWidget>
      </a>

      <a href="https://discord.gg/F8uruMRYYr" target="_blank" rel="noreferrer">
        <SocialWidget isPro={isPro}>
          <div>
            {isPro ? (
              <img src={DiscordIconWhite} alt="Discord" />
            ) : (
              <img src={DiscordIcon} alt="Discord" />
            )}
          </div>
          <div>
            <h4>Discord</h4>
            <h5>https://discord.gg/F8uruMRYYr</h5>
          </div>
        </SocialWidget>
      </a>

      <a href="https://t.me/keyfi_ai" target="_blank" rel="noreferrer">
        <SocialWidget isPro={isPro}>
          <div>
            {isPro ? (
              <img src={TelegramIconWhite} alt="Telegram" />
            ) : (
              <img src={TelegramIcon} alt="Telegram" />
            )}
          </div>
          <div>
            <h4>Telegram</h4>
            <h5>https://t.me/keyfi_ai</h5>
          </div>
        </SocialWidget>
      </a>
    </SocialWidgetWrap>
  );
}
