import styled, { css } from "styled-components";

export const MenuComponent = styled.div`
  background: transparent;
  box-sizing: border-box;
  height: 77px;
  left: 0;
  margin: 0 auto;
  position: fixed;
  top: 0;
  transition: background 0.2s;
  width: 100%;
  z-index: 5;
`;

export const MenuWrap = styled.div`
  background: transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 77px;
  justify-content: space-between;
  margin: 0 calc((100% - 1200px) / 2);
  max-width: 1200px;
  padding: 16px 0;
  transition: all 0.4s;
  width: 100%;

  @media (max-width: 1200px) {
    margin: 0 1.25em;
    max-width: 100%;
    // padding: 1em;
    width: initial;
  }
`;
export const MenuWrapItem = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  img {
    height: 35px;
  }
  .logo_pro {
    background: ${(props) => props.theme.brandBlue};
    color: white;
    position: absolute;
    bottom: -8px;
    right: 0;
    padding: 1px 5px;
    letter-spacing: 0.5em;
    border-radius: 2.5px;
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      line-height: 15.25px;
      font-weight: 600;
      margin-right: -0.5em;
    }
  }

  @media (max-width: 568px) {
    img {
      height: 35px;
    }
  }
`;
export const MenuItems = styled.div`
  color: ${(props) => props.theme.white1};
  display: flex;
  align-items: center;
  gap: 2em;
  margin-left: 0.5rem;

  > a,
  button {
    color: #f3f7ff;
    text-align: center;
    position: relative;
    font-weight: 600;
    font-size: 1rem;
  }

  > button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: 0;
  }

  a.pro_link {
    padding: 2px 7px;
    background: ${(props) => props.theme.brandBlue};
    border-radius: 6px;
    line-height: 19px;
  }

  a.active::before {
    position: absolute;
    background: ${(props) => props.theme.yellow};
    content: "";
    height: 2px;
    left: 45%;
    margin-top: 26px;
    width: 8px;
  }

  a:hover {
    color: ${(props) => props.theme.yellow};
  }

  @media (max-width: 1050px) {
    display: none;
  }
`;
export const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: 1050px) {
    display: flex;
  }
`;
export const MobileMenuLayer = styled.div`
  background: ${(props) => (props.isPro ? `#101B2B` : `#003883`)};
  height: 100vh;
  top: 0;
  left: 0;
  padding: 2em 2em 2em 2.5em;
  position: absolute;
  width: 100vw;
  z-index: 0;

  .languageList {
    margin-top: 3.5rem;
  }
  .mobile_nav {
    margin-top: 3.5rem;
    a {
      display: block;
      color: #f3f7ff;
      font-size: 1.5em;
      margin-bottom: 1.5em;
    }
    a.pro_link {
      padding: 2px 7px;
      background: ${(props) => props.theme.brandBlue};
      border-radius: 6px;
      font-size: 20px;
      font-weight: 600;
      width: max-content;
    }
    a.active {
      ::after {
        background: ${(props) => props.theme.blueTransparent};
        content: "";
        height: 61px;
        color: #122a4b;
        left: 20px;
        margin-top: -15px;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }
  }

  .ctaMenu {
    background: #58afff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    justify-content: center;
  }

  .ctaMenu img {
    width: 11px;
    height: 11px;
  }

  li {
    outline: none;
  }

  li li.active .pro_link {
    border-radius: 6px;
    width: 62px;
    line-height: 22px;
    text-align: center;
    padding: 7px 0;
    height: 32px;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    background: ${(props) => props.theme.brandBlue};
    border-radius: 6px;
  }

  .pro_link.active {
    color: #ffffff;
  }

  li:focus::after {
    background: ${(props) => props.theme.brandBlack};
    content: "";
    height: 61px;
    left: 20px;
    margin-top: -15px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  img {
    height: 35px;
  }

  .pro_icon {
    background: ${(props) => props.theme.brandBlue};
    color: white;
    position: absolute;
    top: 59px;
    left: 156px;
    padding: 1px 5px;
    letter-spacing: 0.5em;
    border-radius: 2.5px;
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      line-height: 15.25px;
      font-weight: 600;
      margin-right: -0.5em;
    }
  }

  .languageList {
    p {
      display: inline-block;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-left: 1em;
    }

    .langTitle {
      font-size: 20px;
      margin: 0;
    }

    li {
      margin-bottom: 1em;
    }

    img {
      width: 15px;
      height: 11px;
    }

    .tick {
      margin: 0;
      padding: 0;
    }

    .tick {
      visibility: hidden;
    }

    #${(props) => props.current} {
      visibility: visible;
    }
  }
  @media (max-width: 568px) {
    img {
      height: 35px;
    }
    .pro_icon {
      top: 49px;
      left: 110px;
    }
  }
`;

export const LinkDropdown = styled.button`
  position: relative;
  :hover {
    .menu_item_dropdown {
      display: block;
    }
  }
  .menu_item_dropdown {
    position: absolute;
    display: none;
    z-index: 3;
    top: 100%;
    left: 0;
    .dropdown_items {
      margin-top: 1rem;
      text-align: left;
      min-width: 140px;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.languageBorder};
      > a {
        display: block;
        color: white;
        background: ${(props) => props.theme.blueTransparent};
        padding: 0.5rem 0;
        text-align: center;
        :hover {
          background: ${(props) => props.theme.languageHover};
        }
        :first-of-type {
          border-radius: 6px 6px 0 0;
        }
        :last-of-type {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }
  ${(props) =>
    props.show &&
    css`
      .menu_item_dropdown {
        display: block;
      }
    `}
`;

export const LanguageSelect = styled.div`
  background: ${(props) => props.theme.blueTransparent};
  border: 1px solid ${(props) => props.theme.languageBorder};
  border-radius: 6px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 6px;

  p {
    font-weight: 600;
    font-size: 1em !important;
    line-height: 20px;
    margin: 0;
  }

  .arrow {
    width: 1em;
  }

  @media (max-width: 576px) {
    height: 44px;
    width: 132px;
    justify-content: center;

    .global {
      width: 20px;
    }

    .arrow {
      width: 11px;
    }
  }
`;
export const LanguageOption = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.languageBorder};
  border-radius: 6px;
  cursor: pointer;
  top: 85%;
  min-width: 140px;

  button {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    width: 100%;
    background: ${(props) => props.theme.blueTransparent};
    :hover {
      background: ${(props) => props.theme.languageHover};
    }
    :first-of-type {
      border-radius: 6px 6px 0 0;
    }
    :last-of-type {
      border-radius: 0 0 6px 6px;
    }
    p {
      font-size: 16px;
      color: white;
      font-weight: 600;
      text-align: left;
      margin: 0;
      margin-left: 7%;
    }
  }

  .tick {
    visibility: hidden;
  }

  #${(props) => props.current} {
    visibility: visible;
  }
`;
