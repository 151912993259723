import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import LogoWhite from "../images/logoWhite.svg";
import CloseIcon from "../images/close.svg";
import HamburgerIcon from "../images/hamburger2.svg";
import ExternalWhiteIcon from "../images/externalWhite.svg";
import GlobalIcon from "../images/global.svg";
import DownwardArrow from "../images/downward-arrow.svg";
import GreenTrick from "../images/green-tick.svg";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import "./menu.css";
import {
  LanguageOption,
  LanguageSelect,
  LinkDropdown,
  MenuComponent,
  MenuItems,
  MenuWrap,
  MenuWrapItem,
  MobileMenuContainer,
  MobileMenuLayer
} from "./Menu.styles";

const HamburgerMenu = ({ menuIcon, handleClick }) => {
  return (
    <MobileMenuContainer onClick={handleClick}>
      <img src={menuIcon} alt="Menu icon" />
    </MobileMenuContainer>
  );
};

export default function Menu(props) {
  const { i18n, t } = useTranslation();
  const { changeLanguage } = useI18next();

  const [bg, setBg] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const isPro = props.isPro;
  const activeMenu = props.activeMenu;
  const credential = props.credential;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", function (event) {
      var newWidth = window.innerWidth;
      if (newWidth >= 1046) {
        setIsMobileMenuOpen(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    window.scrollY > 80 ? setBg(true) : setBg(false);
  };

  const handleMobileMenu = () => {
    isMobileMenuOpen ? setIsMobileMenuOpen(false) : setIsMobileMenuOpen(true);
  };

  const handleLangOption = (option) => {
    setIsLanguageOpen(option);
  };

  const handleLangMenuOption = () => {
    if (isLanguageMenuOpen) {
      setIsLanguageMenuOpen(false);
      setIsMobileMenuOpen(false);
    } else {
      setIsLanguageMenuOpen(true);
    }
  };

  const MenuItemsComponent = ({ activeMenu }) => {
    return (
      <MenuItems>
        <Link className={activeMenu === "roadmap" ? "active" : ""} to="/roadmap">
          {t("homepage.footer.roadmap")}
        </Link>

        <Link className={`pro_link ${isPro ? "active" : ""}`} to="/pro">
          {t("homepage.footer.pro")}
        </Link>

        <Link className={activeMenu === "credentials" ? "active" : ""} to="/credentials">
          {t("homepage.footer.credential")}
        </Link>

        <Link className={activeMenu === "whitepaper" ? "active" : ""} to="/whitepaper">
          {t("homepage.menu.whitepaper")}
        </Link>

        <LinkDropdown show={showDropdown} onClick={() => setShowDropdown((prev) => !prev)}>
          {t("homepage.footer.about")} <img className="arrow" alt="Arrow" src={DownwardArrow} />
          <div className="menu_item_dropdown">
            <div className="dropdown_items">
              {" "}
              <Link to="/team"> {t("homepage.footer.team")}</Link>
              <Link to="/about"> {t("homepage.footer.company")}</Link>
            </div>
          </div>
        </LinkDropdown>

        {!isPro && (
          <a href="https://app.keyfi.com">
            <button className="ctaMenu">
              {t("homepage.getStart.keyfi.launchApp")}
              <img className="externalIcon" src={ExternalWhiteIcon} alt="External icon" />
            </button>
          </a>
        )}
        <div
          onMouseEnter={() => {
            handleLangOption(true);
          }}
          onMouseLeave={() => {
            handleLangOption(false);
          }}
          style={{ height: "100%", paddingBottom: "1em", marginTop: "1em" }}
        >
          <LanguageSelect>
            <img className="global" alt="Global icon" src={GlobalIcon} />
            <p>{i18n.languages[0].toUpperCase() || "EN"}</p>
            <img className="arrow" alt="Arrow" src={DownwardArrow} />
          </LanguageSelect>
          {isLanguageOpen ? (
            <LanguageOption current={i18n.languages[0]} className="LanguageOption">
              <button
                onClick={() => {
                  changeLanguage("en");
                }}
              >
                <img src={GreenTrick} alt="en" id="en" className="tick" />
                <p>English</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("zh");
                }}
              >
                <img src={GreenTrick} alt="zh" id="zh" className="tick" />
                <p>中文</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("ar");
                }}
              >
                <img src={GreenTrick} alt="ar" id="ar" className="tick" />
                <p>عربي</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("tr");
                }}
              >
                <img src={GreenTrick} alt="tr" id="tr" className="tick" />
                <p>Türkçe</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("kr");
                }}
              >
                <img src={GreenTrick} alt="kr" id="kr" className="tick" />
                <p>한국인</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("jp");
                }}
              >
                <img src={GreenTrick} alt="jp" id="jp" className="tick" />
                <p>日本語</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("ru");
                }}
              >
                <img src={GreenTrick} alt="ru" id="ru" className="tick" />
                <p>русский</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("de");
                }}
              >
                <img src={GreenTrick} alt="de" id="de" className="tick" />
                <p>Deutsch</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("fn");
                }}
              >
                <img src={GreenTrick} alt="fn" id="fn" className="tick" />
                <p>Français</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("it");
                }}
              >
                <img src={GreenTrick} alt="it" id="it" className="tick" />
                <p>Italiano</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("sp");
                }}
              >
                <img src={GreenTrick} alt="sp" id="sp" className="tick" />
                <p>Español</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("vi");
                }}
              >
                <img src={GreenTrick} alt="vi" id="vi" className="tick" />
                <p>Tiếng Việt</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("pt");
                }}
              >
                <img src={GreenTrick} alt="pt" id="pt" className="tick" />
                <p>Português</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("pl");
                }}
              >
                <img src={GreenTrick} alt="pl" id="pl" className="tick" />
                <p>język polski</p>
              </button>
              <button
                onClick={() => {
                  changeLanguage("gk");
                }}
              >
                <img src={GreenTrick} alt="gk" id="gk" className="tick" />
                <p>Ελληνικά</p>
              </button>
            </LanguageOption>
          ) : (
            <></>
          )}
        </div>
      </MenuItems>
    );
  };

  const MobileMenuLayerComponent = ({ activeMenu, handleClick }) => {
    const { t } = useTranslation();
    const language = {
      en: "English",
      zh: "中文",
      kr: "한국인",
      jp: "日本語",
      ru: "русский",
      ar: "عربي",
      tr: "Türkçe",
      de: "Deutsch",
      fn: "Français",
      it: "Italiano",
      sp: "Español",
      vi: "Tiếng Việt",
      pt: "Português",
      pr: "język polski",
      gk: "Ελληνικά"
    };

    return (
      <MobileMenuLayer isPro={isPro}>
        <img
          src={LogoWhite}
          alt="KeyFi logo"
          style={{
            position: "absolute",
            top: "19px",
            left: "20px",
            cursor: "pointer"
          }}
        />
        {isPro && (
          <div className="pro_icon">
            <span>PRO</span>
          </div>
        )}
        <div className="mobile_nav">
          {!isPro && (
            <a href="https://app.keyfi.com" target="_blank" rel="noopener noreferrer">
              <button className="ctaMenu">
                {t("homepage.getStart.keyfi.launchApp")}
                <img className="externalIcon" src={ExternalWhiteIcon} alt="External icon" />
              </button>
            </a>
          )}
          <Link className={activeMenu === "roadmap" ? "active" : ""} to="/roadmap">
            {t("homepage.footer.roadmap")}
          </Link>
          <Link className={`pro_link ${isPro ? "active" : ""}`} to="/pro">
            {t("homepage.footer.PRO")}
          </Link>
          <Link className={activeMenu === "credentials" ? "active" : ""} to="/credentials">
            {t("homepage.footer.credential")}
          </Link>
          <Link className={activeMenu === "whitepaper" ? "active" : ""} to="/whitepaper">
            {t("homepage.footer.whitepaper")}
          </Link>
          <Link className={activeMenu === "about" ? "active" : ""} to="/about">
            {t("homepage.footer.about")}
          </Link>

          <Link className={activeMenu === "team" ? "active" : ""} to="/team">
            {t("homepage.footer.team")}
          </Link>
        </div>

        <LanguageSelect
          onClick={() => {
            handleLangMenuOption();
          }}
        >
          <img className="global" alt="global" src={GlobalIcon} />
          <p>{language[i18n.languages[0]]}</p>
          <img className="arrow" alt="arrow" src={DownwardArrow} />
        </LanguageSelect>
        <img
          src={CloseIcon}
          alt="Close icon"
          onClick={handleClick}
          style={{
            position: "absolute",
            top: "25px",
            right: "20px",
            cursor: "pointer",
            height: "25px"
          }}
        />
      </MobileMenuLayer>
    );
  };

  const LanguageOptionComponent = ({ activeMenu, handleClick }) => {
    return (
      <MobileMenuLayer style={{ background: "#122A4B" }} isPro={isPro} current={i18n.languages[0]}>
        <img
          src={LogoWhite}
          alt="KeyFi logo"
          style={{
            position: "absolute",
            top: "19px",
            left: "20px",
            cursor: "pointer"
          }}
        />
        {isPro && (
          <div className="pro_icon">
            <span>PRO</span>
          </div>
        )}
        <ul className="languageList">
          <li>
            <p className="langTitle">{t("language.languageSelector")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("en");
            }}
          >
            <img src={GreenTrick} alt="en" id="en" className="tick" />
            <p>{t("language.english")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("zh");
            }}
          >
            <img src={GreenTrick} alt="zh" id="zh" className="tick" />
            <p>{t("language.chinese")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("ar");
            }}
          >
            <img src={GreenTrick} alt="ar" id="ar" className="tick" />
            <p>{t("language.arabic")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("tr");
            }}
          >
            <img src={GreenTrick} alt="tr" id="tr" className="tick" />
            <p>{t("language.turkish")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("kr");
            }}
          >
            <img src={GreenTrick} alt="kr" id="kr" className="tick" />
            <p>{t("language.korean")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("jp");
            }}
          >
            <img src={GreenTrick} alt="jp" id="jp" className="tick" />
            <p>{t("language.japanese")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("ru");
            }}
          >
            <img src={GreenTrick} alt="ru" id="ru" className="tick" />
            <p>{t("language.russian")}</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("de");
            }}
          >
            <img src={GreenTrick} alt="de" id="de" className="tick" />
            <p>Deutsch</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("fn");
            }}
          >
            <img src={GreenTrick} alt="fn" id="fn" className="tick" />
            <p>Français</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("it");
            }}
          >
            <img src={GreenTrick} alt="it" id="it" className="tick" />
            <p>Italiano</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("sp");
            }}
          >
            <img src={GreenTrick} alt="sp" id="sp" className="tick" />
            <p>Español</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("vi");
            }}
          >
            <img src={GreenTrick} alt="vi" id="vi" className="tick" />
            <p>Tiếng Việt</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("pt");
            }}
          >
            <img src={GreenTrick} alt="pt" id="pt" className="tick" />
            <p>Português</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("pl");
            }}
          >
            <img src={GreenTrick} alt="pl" id="ptpl" className="tick" />
            <p>język polski</p>
          </li>
          <li
            onClick={() => {
              changeLanguage("gk");
            }}
          >
            <img src={GreenTrick} alt="gk" id="gk" className="tick" />
            <p>Ελληνικά</p>
          </li>
        </ul>
        <img
          src={CloseIcon}
          alt="Close icon"
          onClick={handleClick}
          style={{
            position: "absolute",
            top: "25px",
            right: "20px",
            cursor: "pointer",
            height: "25px"
          }}
        />
      </MobileMenuLayer>
    );
  };

  return (
    <MenuComponent
      id="menuItems"
      onScroll={handleScroll}
      style={
        bg || credential
          ? {
              background: isPro ? `#101B2B` : `#003883`,
              boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.5)"
            }
          : { background: "transparent" }
      }
    >
      <MenuWrap>
        <MenuWrapItem>
          <Link to="/">
            <img src={LogoWhite} alt="KeyFi logo" id="logo-icon" />
            {isPro && (
              <div className="logo_pro">
                <span>PRO</span>
              </div>
            )}
          </Link>
        </MenuWrapItem>
        <HamburgerMenu menuIcon={HamburgerIcon} handleClick={handleMobileMenu} />
        {isMobileMenuOpen ? (
          isLanguageMenuOpen ? (
            <LanguageOptionComponent handleClick={handleLangMenuOption} />
          ) : (
            <MobileMenuLayerComponent activeMenu={activeMenu} handleClick={handleMobileMenu} />
          )
        ) : (
          ""
        )}
        <MenuItemsComponent activeMenu={activeMenu} />
      </MenuWrap>
    </MenuComponent>
  );
}
