import React from "react";
import styled from "styled-components";
import { breakPoints } from "./theme";

const PrimaryButton = styled.button`
  min-width: 100px;
  padding: 12px 1.5rem;
  color: ${(props) => props.theme.white1};
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.25em;
  border-radius: 4px;
  box-sizing: border-box;
  background: linear-gradient(256.02deg, #0ca7eb 2.19%, #005ad3 188.15%);
  border-radius: 4px;
  z-index: 2;
  &:hover {
    background: linear-gradient(256.02deg, #005ad3 2.19%, #0ca7eb 188.15%);
  }

  /* @media (max-width: 768px) {
      padding: 8px;
    } */
`;

const StyledButton = styled.button`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.font};
  padding: ${(props) => props.padding};
  border-radius: 8px;
  color: ${(props) => props.theme.white1};
  background: ${(props) => props.theme.darkBlue};
  outline: 0;
  border: 0;
  cursor: pointer;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : null)};
  transition: all 0.2s ease-in-out;
  @media (max-width: ${breakPoints.sm}px) {
    height: 48px;
  }

  :hover {
    background: ${(props) => props.theme.brandBlue};
  }
`;

// const SecondaryButton = styled.button`
//   font-weight: ${(props) => props.weight};
//   font-size: ${(props) => props.font};
//   padding: ${(props) => props.padding};
//   border-radius: 8px;
//   color: ${(props) => props.theme.btnPrimaryBg};
//   background: ${(props) => props.theme.btnPrimaryColor};
//   outline: 0;
//   border: 1px solid ${(props) => props.theme.btnPrimaryBg};
//   cursor: pointer;
//   text-transform: uppercase;
//   @media (max-width: ${breakPoints.sm}px) {
//     height: 48px;
//   }
// `;

const DisabledButton = styled.button`
  margin-top: 1rem;
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.font};
  padding: ${(props) => props.padding || "8px 24px"};
  border-radius: 4px;
  color: ${(props) => props.theme.text_grey};
  background: ${(props) => props.theme.grey5};
  outline: 0;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  @media (max-width: ${breakPoints.sm}px) {
    height: 48px;
  }
`;

const Secondary = styled.button`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  color: ${(props) => props.theme.text_secondary};
  background: ${(props) => props.theme.btnSecondary};
  outline: 0;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  @media (max-width: ${breakPoints.sm}px) {
    height: 48px;
  }

  :hover {
    background: ${(props) => props.theme.lightBlue};
    color: ${(props) => props.theme.text_secondary};
  }

  :focus {
    background: ${(props) => props.theme.text_secondary};
    color: ${(props) => props.theme.grey3};
  }
`;

export default function Button(props) {
  return props.secondary ? (
    <Secondary {...props}>{props.children}</Secondary>
  ) : props.primary ? (
    <PrimaryButton {...props}>{props.children}</PrimaryButton>
  ) : props.disabled ? (
    <DisabledButton {...props}>{props.children}</DisabledButton>
  ) : (
    <StyledButton {...props}>{props.children}</StyledButton>
  );
}
